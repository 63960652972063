<!-- layouts/blank.vue -->
<script lang="ts" setup></script>

<template>
  <main>
    <slot />
  </main>
</template>

<style lang="css" scoped></style>
